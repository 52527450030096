<style scoped lang="less">
  .waring-menus {
    display: flex;
    justify-content: space-around;
    padding: 34px 0;
    border-bottom: 1px solid #F0F3F8;
    .menu {
      transition: all .3s;
      cursor: pointer;
      color: #707070;
      text-align: center;
      font-size: 17px;
      .iconfont {
        position: relative;
        font-size: 30px;
        .num {
          position: absolute;
          left: 100%;
          top: -3px;
          transform: translateX(-50%);
          font-size: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 20px;
          white-space: nowrap;
          height: 20px;
          border-radius: 10px;
          background-color: #EB3030;
          color: #FFF;
        }
      }
    }
  }
  .waring-list {
    margin: 0 20px 20px 20px;
    .item {
      display: flex;
      align-items: flex-start;
      margin-top: 10px;
      // cursor: pointer;
      .type, .content, .iconfont { transition: all .3s; }
      .iconfont {
        position: relative;
        top: 5px;
        font-size: 20px;
        color: #707070;
        margin-right: 20px;
      }
      .type {
        font-size: 14px;
        color: #707070;
      }
      .content {
        margin-top: 5px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .full-over-height {
    flex: 1; height: 0; position: relative; display: flex;
    flex-direction: column;
  }
</style>

<style lang="less">
.pink-theme {
  .waring-menus {
    .menu:hover, .menu.active {
      color: #FB6D8F;
    }
  }
  .waring-list {
    .item:hover {
      .type, .content, .iconfont {
        // color: #FB6D8F;
      }
    }
  }
}
.drak-theme, .blue-theme {
  .waring-menus {
    .menu:hover {
      color: #6B75CA;
    }
  }
  .waring-list {
    .item:hover {
      .type, .content, .iconfont {
        // color: #6B75CA;
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="waring-menus">
      <div class="menu" @click="authJump('agreementWarning', 'worker.agreement_warning')" @mouseenter="status.waringType = 0" :class="{active: status.waringType === 0 && false}">
        <i class="iconfont icon-a-zu1455">
          <span v-if="waringList.type0.length > 0" class="num">{{waringList.type0.length}}</span>
        </i>
        <div>过期预警</div>
      </div>
      <div class="menu" @click="authJump('jobTitleWarning', 'worker.job_title_queue_warning')" @mouseenter="status.waringType = 1" :class="{active: status.waringType === 1 && false}">
        <i class="iconfont icon-guoqiyujing1">
          <span v-if="waringList.type1.length > 0" class="num">{{waringList.type1.length}}</span>
        </i>
        <div>岗位预警</div>
      </div>
      <div class="menu" @click="authJump('agreementTurnwarning', 'worker.agreement_turn_warning')" @mouseenter="status.waringType = 2" :class="{active: status.waringType === 2 && false}">
        <i class="iconfont icon-a-zu14531">
          <span v-if="waringList.type2.length > 0" class="num">{{waringList.type2.length}}</span>
        </i>
        <div>转正预警</div>
      </div>
    </div>
    <div class="full-over-height waring-list" @mouseenter="stopScroll" @mouseleave="startScroll" ref="list" style="overflow: auto;">
      <div style="margin: 10px;color: #657180;" v-if="!activeWaringList.length">暂无数据</div>
      <div class="item" v-for="(item, i) in activeWaringList" :key="i + '-' + status.waringType">
        <i class="iconfont" :class="item.icon"></i>
        <div @click="jumpByType">
          <div class="type">{{item.type}}</div>
          <div class="content">{{item.content}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  agreementRequest,
  jobTitleRequest
} from '@/api'

import {
  dateOperating
} from '@/fmlib'

let timer = null

export default {
  inject: ['authFun', 'authJump'],
  data () {
    return {
      status: {
        waringType: 0
      },
      waringList: {
        type0: [],
        type1: [],
        type2: [],
      },
    }
  },
  computed: {
    activeWaringList () {
      // return this.waringList['type' + this.status.waringType]
      return [...this.waringList.type0, ...this.waringList.type1, ...this.waringList.type2]
    }
  },
  methods: {
    jumpByType () {
      // const jump = [
      //   { authKey: 'agreementWarning', url: 'worker.agreement_warning' },
      //   { authKey: 'jobTitleWarning', url: 'worker.job_title_queue_warning' },
      //   { authKey: 'agreementTurnwarning', url: 'worker.agreement_turn_warning' }
      // ][this.status.waringType]

      // if (this.authFun[jump.authKey]) {
      //   this.$router.push({ name: jump.url })
      // }
    },
    async loadAgreementWarningCount () {
      let data = await agreementRequest.get()
      let workerMap = {}
      data.forEach(v => {
        v.lastDays = 10000
        if (v.endDate) {
          let ms = new Date(v.endDate).getTime() - new Date().getTime()
          v.lastDays = parseInt(ms / 1000 / 60 / 60 / 24) + 1
        }
        if (!workerMap[v.workerId]) {
          workerMap[v.workerId] = [v]
        } else {
          workerMap[v.workerId].push(v)
        }
      })
      let datas = []
      Object.keys(workerMap).forEach((workerId) => {
        let needWarning = workerMap[workerId].filter(v => v.lastDays > 90).length === 0
        if (needWarning) {
          workerMap[workerId].sort((a, b) => b.lastDays - a.lastDays)
          workerMap[workerId][0].warningText = workerMap[workerId][0].type + (workerMap[workerId][0].lastDays > 0 ? '即将过期' : '已过期')
          datas.push(workerMap[workerId][0])
        }
      })
      this.waringList.type0 = datas.map(v => {
        return {
          icon: 'icon-a-zu1455',
          type: v.type,
          content: v.workerName + v.warningText,
          data: v
        }
      })
    },
    async loadJobTitleWarningCount () {
      let parm = {
        maxDiffDay: 30
      }
      let data = await jobTitleRequest.getQueueWarning(parm)
      data.sort((a, b) => {
        return (a.lelel || 0) - (b.lelel || 0)
      })
      this.waringList.type1 = data.map(v => {
        return {
          icon: 'icon-guoqiyujing1',
          type: '岗位预警',
          content: v.type + v.title + '级',
          data: v
        }
      })
    },
    async loadAgreementTurnwarningCount () {
      let maxTryEndDate = dateOperating.computeDay({days: 30, date: new Date(), format: 'yy-mm-dd'})
      let datas = await agreementRequest.getTurn({
        maxTryEndDate
      })
      this.waringList.type2 = datas.map(v => {
        return {
          icon: 'icon-a-zu14531',
          type: '转正预警',
          content: v.workerName + v.type,
          data: v
        }
      })
    },
    stopScroll () {
      clearTimeout(timer)
    },
    startScroll () {
      clearTimeout(timer)
      const { scrollHeight, offsetHeight, scrollTop } = this.$refs.list
      if (scrollHeight > offsetHeight && scrollTop < scrollHeight - offsetHeight) {
        this.$refs.list.scrollTop += 2
        timer = setTimeout(this.startScroll, 100)
      }
    }
  },
  async mounted () {
    let load = []
    if (this.authFun.agreementWarning) {
      load.push(this.loadAgreementWarningCount())
    }
    if (this.authFun.jobTitleWarning) {
      load.push(this.loadJobTitleWarningCount())
    }
    if (this.authFun.agreementTurnwarning) {
      load.push(this.loadAgreementTurnwarningCount())
    }
    if (load.length) {
      await Promise.all(load)
      this.startScroll()
    }
  },
  destroyed () {
    this.stopScroll()
  }
}
</script>
